import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './Home';
import Jobs from './Jobs';
import Contact from './Contact';
import Projects from './Projects';
import Bathroom from './Bathroom';
import Cuisines from './Cuisines';
import Divers from './Divers';
import Armoires from './Armoires';
import PageNotFound from './PageNotFound';
import BannerEvent from './components/BannerEvent';

function App() {

  useEffect(() => {
    document.querySelectorAll('.nav-link').forEach(link => {
      link.addEventListener('click', function(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      })
    });
  });

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  });

  return (
    <div className="App">
      <Router>
        <BannerEvent />
        <Nav />
        <Routes>
          <Route index element={<Home />} />
          <Route path="Projets" element={<Projects />} />
          <Route path="Jobs" element={<Jobs />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="/Salle-de-Bains" element={<Bathroom />} />
          <Route path="/Cuisines" element={<Cuisines />} />
          <Route path="/Armoires" element={<Armoires />} />
          <Route path="/Divers" element={<Divers />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
