import React, { useState } from 'react';

const BannerEvent = () => {

    const [active, setActive] = useState(true);

  return (
    <div className="banner relative text-white">
        <div onClick={() => { setActive(false) }} className="close-banner absolute right-4 top-4 text-xs underline cursor-pointer">
            fermer
        </div>
        <div className={`banner-wrapper flex justify-center ${active === false && 'hidden'}`}>
            <h2 className="banner-title py-4 px-4 text-sm">FS Agencebois sera présent au <a className="hover:text-yellow hover:underline" target='_blank' rel='noreferrer' href="https://www.comptoir-veveyse.ch/">comptoir de la Veveyse</a> – du <b>18</b> au <b>22</b> OCT 2023</h2>
        </div>
    </div>
  )
}

export default BannerEvent